/* src/components/HomePage.css */

.homepage {
  background-color: #2e81b3;
}

.name-text {
  color: #f8be45;
}

.square-image {
  width: 1000px;
  height: 1000px;
  object-fit: cover; /* Ensures the image covers the square dimensions without distortion */
  border-radius: 10px; /* Optional: Rounded corners for a modern look */
}

.paragraph-text {
  font-size: 1.25rem;
  line-height: 1.6;
  color: white;
}

@media (max-width: 768px) {
  .square-image {
    width: 500px;
    height: 500px;
  }

  h1 {
    font-size: 3rem;
  }

  .paragraph-text {
    font-size: 1.1rem;
  }
}
