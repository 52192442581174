footer {
    background-color: #f5f5f5;
    color: white;
    text-align: center;
    padding: 20px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  
  footer p {
    margin: 0;
  }
  
  .social-links {
    margin-top: 10px;
  }
  
  .social-links a {
    margin: 0 10px;
    color: black;
    text-decoration: none;
  }
  
  .social-links a:hover {
    text-decoration: underline;
  }
  