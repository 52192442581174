/* src/components/Header.css */
header {
    background-color: #f5f5f5;
    /* padding: 10px; */
}
  
nav ul {
  display: flex;
  list-style-type: none;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

nav ul li {
  padding: 10px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

nav ul li a:hover {
  text-decoration: underline;
}
